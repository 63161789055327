<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <div v-if="!error">
        <h1 class="text-2xl font-bold">Post No Debit Accounts</h1>
        <div class="flex mt-12 gap-x-2 items-center">
          <input
            type="text"
            class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
            name=""
            placeholder="Search for business name or email address"
            id=""
            v-model="search"
          />
          <button :disabled="!search" @click="showSearchOptions = false; currentPage = 1; fetchRecords()" :class="{'bg-blue-900' : search, 'bg-gray-500': !search}" class="uppercase flex shadow m-2  text-white focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-3 rounded">
              <span class="flex-1 px-4">Search</span>
          </button>
        </div>
        <div class="mt-6">
          <button @click="showSearchOptions = !showSearchOptions" class="uppercase w-64 flex shadow m-2 bg-blue-600 text-white focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-3 rounded">
              <span class="flex-1 px-4">Filter by Status</span>
              <img src="@/assets/images/filter-icon.png" alt="">
          </button>
           <div v-if="showSearchOptions" class="bg-white w-64 mx-2 border rounded-b-lg text-sm py-3">
              <div class="px-2 py-2 border-b border-gray-200" v-for="(option, index) in searchOptionsList" :key="index">
                  <div class="flex gap-x-2">
                      <input type="checkbox" :checked="option.selected" @change="selectOption(index)" name="" class="">
                      <span>{{ option.label }}</span>
                  </div>
              </div>
              <div class="p-2">
                  <button @click="showSearchOptions = false; fetchRecords()" class="uppercase shadow w-full bg-blue-900 text-white focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-3 rounded">
                      <span class="px-4">Apply</span>
                  </button>
              </div>
          </div>
        </div>
        <div v-if="accountUpdated.status" class="mt-6 border border-green-300 bg-green-100 px-4 py-3 rounded-md text-sm font-medium">
          {{ accountUpdated.message }}
        </div>
        <div class="mt-12" style="max-height: 600px; overflow: scroll">
          <table class="shadow-lg bg-white w-full relative border-collapse">
            <tr>
              <th colspan="2"
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <!-- <th colspan="2"
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Merchant Name
              </th> -->
              <th colspan="2"
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Phone Number
              </th>
              <th colspan="2"
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Email Address
              </th>
              <th colspan="2"
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Account Status
              </th>
              <th colspan="2"
                  style="text-align: center"
                class="bg-blue-100 text-sm border whitespace-nowrap px-8 py-2"
              >
                Actions
              </th>
            </tr>
            <tr v-for="(account, index) in accounts" :key="index">
              <td colspan="2" class="text-xs px-8 py-2">{{account.businessName || 'N/A'}}</td>
              <!-- <td colspan="2" class="text-xs px-8 py-2">N/A</td> -->
              <td colspan="2" class="text-xs px-8 py-2">{{account.phoneNumber || 'N/A'}}</td>
              <td colspan="2" class="text-xs px-8 py-2">{{account.email || 'N/A'}}</td>
              <td colspan="2" class="text-xs px py-2">
                  <span v-if="account.status === 'post_no_debit'">on PND</span>
                  <span v-else>Not on PND</span>
              </td>
              <td colspan="2" class="text-xs flex justify-center gap-x-2 px-8 py-2">
                  <button @click="statusOnPND(account.userguid, 'active')" class="bg-white font-medium rounded-md px-2 border py-2">
                      Remove from PND
                  </button>
                  <button @click="statusOnPND(account.userguid, 'post_no_debit')" class="bg-blue-900 font-medium text-white rounded-md px-2 border py-2">
                      Put on PND
                  </button>
              </td>
            </tr>
            <!-- <tr class="text-xs" v-for="(account, i) in filterAccounts" :key="i">
              <td>
                <input type="checkbox" :value="account.id" v-model="selected" />
              </td>
              <td>{{ account.business_id }}</td>
              <td>{{ account.business_name }}</td>
              <td>{{ account.business_email }}</td>
              <td>{{ account.bank_name }}</td>
              <td>{{ account.custom_account_name }}</td>
              <td>{{ account.custom_account_number }}</td>
              <td>{{ account.store_details.store_name }}</td>
              <td>
                {{ moment(account.created_at).format("DD MMM YYYY - h:mm:ss a") }}
              </td>
              <td>{{ account.black_list_status }}</td>
              <td>
                <button
                  :class="
                    account.black_list_status === true
                      ? 'bg-gray-300 text-black text-xs'
                      : 'bg-red-900 text-white text-xs'
                  "
                  class="py-3 px-6 rounded-md"
                  @click="toggleBlacklist(account.id, !account.black_list_status)"
                >
                  {{
                    account.black_list_status === true ? "Whitelist" : "Blacklist"
                  }}
                </button>
              </td>
            </tr> -->
          </table>
        </div>
        <div class="mt-3">
          <button
            @click="prevPage(currentPage)"
            :disabled="currentPage === 1 ? isDisabled : !isDisabled"
            class="uppercase shadow ml-3 hover:bg-blue-900 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
            :class="
              currentPage === 1
                ? 'bg-blue-50 text-black'
                : 'bg-blue-900 text-white'
            "
          >
            &laquo; Prev
          </button>
          <button
            @click="nextPage(currentPage)"
            class="float-right uppercase shadow ml-3 hover:bg-blue-900 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          >
            Next &raquo;
          </button>
        </div>
      </div>
      <div v-else class="border p-12 rounded-lg mx-12 mt-12">
        <h3 class="text-2xl mb-2 font-bold">Error Occured</h3>
        <p>
          {{error}}
        </p>
        <button @click="clearAndSearch(true)" class="uppercase flex shadow my-2 bg-blue-900 text-white focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-3 rounded">
          Clear
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { json2excel } from "js2excel";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      error: null,
      accountUpdated: {
        message: '',
        status: false,
      },
      isLoading: false,
      currentPage: 0,
      isDisabled: true,
      search: "",
      accounts: [],
      selected: [],
      selectedWhitelist: [],
      searchRange: {
        limit: 10,
        page:0
      },
      searchOptionsList: [
        {
            value: null,
            selected: true,
            label: 'All Records'
        },
        {
            value: "enabled",
            selected: false,
            label: 'On PND'
        },
        {
            value: "disabled",
            selected: false,
            label: 'Not On PND'
        }
      ],
      showSearchOptions: false
    };
  },
  components: {
    Loading,
  },
  async created() {
    this.fetchRecords();
  },
  methods: {
    async statusOnPND(uid, status) {
      this.isLoading = true;
      let response = await this.$store.dispatch(
        "PND_ACCOUNT", {uid, status}
      );
      // this.accounts = response.data

      if (response.status) {
        this.accountUpdated.status = true
        this.accountUpdated.message = response.message
        this.isLoading = false;
        this.error = null
        this.fetchRecords()

        setTimeout(() => {
          this.$set(this.accountUpdated, 'status', false)
        }, 5000)
      } 
      else {
        this.error = response.message
        this.isLoading = false;
      }
    },
    clearAndSearch(clearText = false) {
      if(clearText) {
        this.currentPage = 1
        this.search = ""
        this.searchOptionsList = this.searchOptionsList.map((option, i) => {
          option.selected = option.label === 'All Records' ? true : false;
          return option;
        });
      }
      this.fetchRecords()
    },

    ...mapActions([
      "FETCH_BLACKLISTED_FIXED_ACCOUNTS",
      "BLACKLIST_FIXED_ACCOUNTS",
      "WHITELIST_BULK_FIXED_ACCOUNTS",
      "FETCH_PAGINATED_BLACKLISTED_FIXED_ACCOUNTS",
    ]),
    selectOption(index) {
        this.searchOptionsList = this.searchOptionsList.map((option, i) => {
            if (i === index) {
                option.selected = true;
            } else {
                option.selected = false;
            }
            return option;
        });
    },
    async fetchRecords(searchProperties = this.searchParameters) {
      this.isLoading = true;
      let response = await this.$store.dispatch(
        "FETCH_PND_ACCOUNTS", searchProperties
      );
      this.accounts = response.data

      if (response.status) {
        this.isLoading = false;
        this.error = null
      } 
      else {
        this.error = response.message
        this.isLoading = false;
      }
    },
    downloadCsv() {
      let data = this.GET_FIXED_ACCOUNT_RES.data.items.map((account) => {
        return {
          "Merchant Id": account.business_id,
          "Business Name": account.business_name,
          "Business Email": account.business_email,
          "Bank Name": account.bank_name,
          "Account Name": account.custom_account_name,
          "Account Number": account.custom_account_number,
          "Store Name": account.store_details.store_name,
          "Date Store Created": account.store_details.created_at,
        };
      });
      json2excel({
        data,
        name: "Fixed Accounts (Blacklisted)",
        // formateDate: this.moment().format("DD MMM YYYY - h:mm:ss a"),
      });
    },
    async toggleBlacklist(business_id, status) {
      let res = confirm("Are you sure you want to blacklist this account?");
      if (res === true) {
        this.isLoading = true;
        let data = { fixed_account_id: business_id, black_list_status: status };
        try {
          let res = await this.$store.dispatch(
            "BLACKLIST_FIXED_ACCOUNTS",
            data
          );
          if (res.status == true) {
            alert("Backlist was successful");
            this.$router.go();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}

        this.isLoading = false;
      }
    },
    async whitelistSelected() {
      let res = confirm("Are you sure you want to whitelist these accounts?");
      if (res === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          const selecteObj = {
            fixed_account_id: el,
            black_list_status: false,
          };
          this.selectedWhitelist.push(selecteObj);
        });
        try {
          let res = await this.$store.dispatch(
            "WHITELIST_BULK_FIXED_ACCOUNTS",
            this.selectedWhitelist
          );
          if (res.status == true) {
            alert("Whitelisting was successful");
            this.$router.go();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}

        //this.isLoading = true;
        //let data = { fixed_account_id: business_id, black_list_status: false };
      }
    },

    async prevPage() {
      this.currentPage -=1
      this.fetchRecords()
    },
    async nextPage() {
      this.currentPage +=1
      this.fetchRecords()
    },
  },
  computed: {
    selectedCriteria() {
      return this.searchOptionsList.find(item => item.selected === true) || {value: 'disabled'}
    },
    searchParameters() {
      const criteria = {
        limit: 10,
        page: this.currentPage
      }
      if(this.selectedCriteria && this.selectedCriteria.value) {
        criteria['pnd'] = this.selectedCriteria.value
      }
      if(this.search) {
        criteria['q'] = this.search
      }
      return criteria
    }
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>